import { createGlobalStyle } from 'styled-components'
import theme from './theme'
import { withPrefix } from 'gatsby'
import { media, fluidType, screenSizes } from './utils'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'HelveticaLtStd-Light';
    src: url(${withPrefix('fonts/HelveticaLtStd-Light.woff2')});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaLtStd-Roman';
    src: url(${withPrefix('fonts/HelveticaLtStd-Roman.woff2')});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaLtStd-Bold';
    src: url(${withPrefix('fonts/HelveticaLtStd-Bold.woff2')});
    font-weight: normal;
    font-style: normal;
  }

  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    scrollbar-width: none; // Remove scrollbars in firefox
    -ms-overflow-style: none; // Remove scrollbars in IE10+
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  html {
    overflow: hidden;
    height: 100%;
  }

  body {
    height: 100%;
    overflow: auto;
    background: white;
    font-family: 'HelveticaLtStd-Roman';
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  /**
  |--------------------------------------------------
  | Typography
  |--------------------------------------------------
  */

  p {
    font-family: ${theme.fonts.normal};
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1.5em;
  }

  .bold {
    font-family: ${theme.fonts.bold};
    letter-spacing: -0.8px;
    line-height: 1.2;
    font-size: 1.25em;
    margin-bottom: 0.8em;
    ${media.large`
      ${fluidType(1, 1.25)}
    `}
    ${media.small`
      font-size: 1em;
    `}
  }

  .brand {
    font-family: ${theme.fonts.bold};
    letter-spacing: -0.8px;
    line-height: 1.2;
    font-size: 1.5em;
    margin-bottom: 0.8em;
    ${media.large`
      ${fluidType(1, 1.5)}
    `}
    ${media.small`
      font-size: 1em;
    `}
  }

  .subtitle {
    font-family: ${theme.fonts.medium};
    letter-spacing: -0.2px;
    line-height: 1.2;
    font-size: 1.25em;
    margin-bottom: 0.8em;
    ${media.large`
      ${fluidType(1, 1.25)}
    `}
    ${media.small`
      font-size: 1em;
    `}
  }

  .table-data {
    line-height: 1;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .table-header {
    font-family: ${theme.fonts.bold};
    letter-spacing: -0.4px;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'HelveticaLtStd-Bold';
    margin: 0;
    margin-bottom: 1.5em;
    font-weight: inherit;
    line-height: 1.2;
    letter-spacing: -0.8px;
    font-weight: bold;
    color: ${theme.colors.blackLight};
  }

  h1 {
    margin: 0;
    margin-bottom: 0.8rem;

    font-family: ${theme.fonts.bold};

    font-size: ${theme.scales.majorThird[6]}em;
    ${media.xlarge`
      ${fluidType(
        1.953,
        theme.scales.majorThird[6],
        screenSizes.small / 16,
        screenSizes.xlarge / 16
      )}
    `}
    ${media.small`
      font-size: 1.953em;
    `}
  }

  h2 {
    line-height: 1.3em;
    margin-bottom: 1em;

    font-size: ${theme.scales.majorThird[5]}em;
    ${media.large`
      ${fluidType(theme.scales.perfectFifth[2], theme.scales.majorThird[5])}
    `}
    ${media.small`
      font-size: ${theme.scales.perfectFifth[2]}em;
    `}
  }

  h3 {
    line-height: 1.3em;
    margin-bottom: 1em;

    font-size: ${theme.scales.majorThird[4]}em;
    ${media.large`
      ${fluidType(theme.scales.perfectFifth[2], theme.scales.majorThird[4])}
    `}
    ${media.small`
      font-size: ${theme.scales.perfectFifth[2]}em;
    `}
  }

  h4 {
    line-height: 1.3em;
    margin-bottom: 1em;

    font-size: ${theme.scales.majorThird[3]}em;
    ${media.large`
      ${fluidType(theme.scales.perfectFifth[2], theme.scales.majorThird[3])}
    `}
    ${media.small`
      font-size: ${theme.scales.perfectFifth[2]}em;
    `}
  }

  h5 {
    line-height: 1.3em;
    margin-bottom: 1em;
    color: #0000ff;

    font-size: ${theme.scales.majorThird[3]}em;
    ${media.large`
      ${fluidType(theme.scales.perfectFifth[2], theme.scales.majorThird[3])}
    `}
    ${media.small`
      font-size: ${theme.scales.perfectFifth[2]}em;
    `}
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: initial;
    display: inherit;
  }

  .font-align {
    position: relative;
    bottom: -0.2em;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  /**
  |--------------------------------------------------
  | Navigation arrows
  |--------------------------------------------------
  */

  .arrow-container {
    align-items: center;
    padding: 15%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  @media (max-width: 768px) {
    .arrow-container {
      padding: 7.5%;
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }

  .arrow-container-bottom {
    flex-direction: column;
    padding-bottom: 0;
  }

  .arrow-container-left {
    padding-left: 0;
  }

  .arrow-container-right {
    padding-right: 0;
  }

  .arrow-text-bottom {
    margin-bottom: 0.667em;
    transform: translate(0, 100%);
  }

  .arrow-text-left {
    margin-bottom: 0;
    margin-left: 0.667em;
    transform: translate(-100%, 0);
  }

  .arrow-text-right {
    margin-bottom: 0;
    margin-right: 0.667em;
    text-align: right;
    transform: translate(100%, 0);
  }

  @media (min-width: 992px) {
    .arrow-container-left:hover .arrow-text-left,
    .arrow-container-right:hover .arrow-text-right,
    .arrow-container-bottom:hover .arrow-text-bottom {
      opacity: 1;
      transform: translate(0,0);
      transition: all 0.35s;
    }
  }

  /**
  |--------------------------------------------------
  | ScrollMagic
  |--------------------------------------------------
  */

  .reveal .scrollmagic-pin-spacer {
    position: absolute !important;
    clip: rect(auto,auto,auto,auto);
    width: 100%;
    height: 100% !important;
  }

  /**
  |--------------------------------------------------
  | Fullpage.js
  |--------------------------------------------------
  */

  #fullpage, .section, .fp-table, body, html{
    height: 100%;
  }

  .fp-tableCell {
    width: 100%;
    height: 100%;
  }

  .fade {
    opacity: 1 !important;
    // transform: translate3d(0, -20px, 0);
    transition: opacity 0.35s ease-out;
  }

  /**
  |--------------------------------------------------
  | Project Table
  |--------------------------------------------------
  */

  .projects-overlay-link:hover .bg-img {
    visibility: visible;
    transition: opacity .3s;
    opacity: 0.4;
  }

  .table-link {
    cursor: pointer;
  }

  .table-link:hover {
    text-decoration: underline;
  }

  /**
  |--------------------------------------------------
  | Markdown styles
  |--------------------------------------------------
  */

  .markdown-content > h1:first-child,
  .markdown-content > h2:first-child,
  .markdown-content > h3:first-child,
  .markdown-content > h4:first-child,
  .markdown-content > h5:first-child {
    margin-top: 0;
  }

  .markdown-content h1 {
    margin-bottom: 0.4em;
  }

  .markdown-content h2 {
    margin-top: 1.375em;
    margin-bottom: 1em;
  }

  .markdown-content h3 {
    margin-top: 2.25em;
    margin-bottom: 0.8em;
  }

  .markdown-content h4,
  .markdown-content h5 {
    margin-top: 2.75em;
    margin-bottom: 1em;
  }

  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3,
  .markdown-content h4,
  .markdown-content h5 {
    max-width: 680px;
    margin-right: auto;
  }

  .markdown-content p {
    color:  rgba(0,0,0,0.7);
    max-width: 680px;
    margin-right: auto;
  }

  em {
    font-style: italic;
  }

  .markdown-content a {
    display: inline-block;
    color: #333;
  }

  .markdown-content a:hover {
    text-decoration: underline;
  }

  .blue {
    color: #0000ff;
  }

  .gatsby-resp-image-wrapper {
    max-width: none !important;
    margin: 3.052em 0;
  }

  /**
  |--------------------------------------------------
  | Scrollbars
  |--------------------------------------------------
  */

  ::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space /
    background: transparent; / optional: just make scrollbar invisible /
  }

  / optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
`
