import { config } from 'react-spring'

export const defaultSpring = {
  ...config.default,
  precision: 0.1
}

export const navArrowSpring = {
  ...config.default,
  tension: 340,
  precision: 0.1
}
