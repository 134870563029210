import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { BackgroundImage } from '../project-sections/ImageSection/BackgroundImage'
import { DataCell } from './DataCell'
import { OverlayContext } from '../../contexts/OverlayContext'
import { ScrollContext } from '../../contexts/ScrollContext'

interface ContainerProps {
  columns: number
}

const Container = styled.div<ContainerProps>`
  grid-area: 3 / 2 / 6 / 10;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, auto);
`

const StyledBackgroundImage = styled(BackgroundImage)`
  visibility: ${(p) => p.visible};
  opacity: ${(p) => (p.visible ? 0.4 : 0)};
  transition: all 0.35s;
  filter: ${(props) => (props.visible ? `` : `grayscale(100%)`)};
`

const isBrowser = typeof window !== 'undefined'

interface Props {
  data: any[]
}

export const ProjectsTable: React.FC<Props> = ({ data }) => {
  const { hideOverlays } = useContext(OverlayContext)
  const { setShouldScroll } = useContext(ScrollContext)
  const [state, setState] = useState({
    columns: ['Project', 'Program', 'Location', 'Client', 'Year'],
    columnsMobile: ['Project', 'Program', 'Location'],
    rowHovered: ''
  })

  const handleNavigate = (active, to) => {
    if (active) {
      hideOverlays()
      if (window.location.pathname !== `/${to}`) {
        setShouldScroll(true)
        navigate(`/${to}`)
      }
    }
  }

  const handleHoverEnter = (id) => {
    setState({
      ...state,
      rowHovered: id
    })
  }

  const handleHoverOut = () => {
    setState({
      ...state,
      rowHovered: ''
    })
  }

  const renderData = () => {
    let items = []

    for (let i = 0; i < data.length; i++) {
      const row = data[i]

      const active = !!row.href
      const rowHovered = active && state.rowHovered === row.id

      const cellProps = {
        rowHovered,
        onHover: handleHoverEnter,
        onHoverOut: handleHoverOut,
        uuid: row['id'],
        onClick: () => handleNavigate(active, row.href)
      }

      items.push(
        <DataCell
          className="projects-overlay-link"
          key={`name-${i}`}
          {...cellProps}
        >
          {row.image && (
            <StyledBackgroundImage
              visible={active && rowHovered}
              className="bg-img"
              image={row.image.gatsbyImageData}
            />
          )}
          <p className="table-data mb-0">{row['name']}</p>
        </DataCell>,

        <DataCell key={`program-${i}`} {...cellProps}>
          <p className="table-data mb-0">{row['program']}</p>
        </DataCell>,

        <DataCell key={`location-${i}`} {...cellProps}>
          <p className="table-data mb-0">{row['location']}</p>
        </DataCell>,

        <DataCell key={`client-${i}`} {...cellProps}>
          <p className="table-data mb-0">{row['client']}</p>
        </DataCell>,

        <DataCell key={`year-${i}`} {...cellProps}>
          <p className="table-data mb-0">{row['year']}</p>
        </DataCell>
      )
    }

    return items
  }

  const renderDataMobile = () => {
    let items = []

    for (let i = 0; i < data.length; i++) {
      const row = data[i]

      let fluid = null
      if (row.image) {
        fluid = row.image.fluid
      }

      const active = !!row.href

      items.push(
        <DataCell className="projects-overlay-link" key={`name-${i}`}>
          {fluid && (
            <StyledBackgroundImage
              active={active}
              className="bg-img"
              image={fluid}
            />
          )}

          {active ? (
            <a
              className="table-link"
              onClick={() => handleNavigate(active, row.href)}
            >
              <p className="table-data mb-0">{row['name']}</p>
            </a>
          ) : (
            <p className="table-data mb-0">{row['name']}</p>
          )}
        </DataCell>,

        <DataCell key={`program-${i}`}>
          <p className="table-data mb-0">{row['program']}</p>
        </DataCell>,

        <DataCell key={`location-${i}`}>
          <p className="table-data mb-0">{row['location']}</p>
        </DataCell>
      )
    }

    return items
  }

  const { columns, columnsMobile } = state

  let isMobile = false

  if (isBrowser) {
    isMobile = window.innerWidth < 700
  }

  const numberOfColumns = isMobile ? columnsMobile.length : columns.length
  const headerColumns = isMobile ? columnsMobile : columns

  return (
    <Container columns={numberOfColumns}>
      {headerColumns.map((col, i) => (
        <DataCell key={i}>
          <p className="table-data table-header mb-0">{col}</p>
        </DataCell>
      ))}

      {isMobile ? renderDataMobile() : renderData()}
    </Container>
  )
}
