import React, { useContext, useState } from 'react'
import { NavigationContext } from './NavigationContext'

export enum Overlay {
  Projects = 'Projects',
  About = 'About'
}
export interface OverlayContextValues {
  currentOverlay: Overlay
  hideOverlays: () => void
  projects: any[]
  setProjects: (values: any[]) => void
  about: any[]
  setAbout: (values: any[]) => void
  toggleOverlay: (overlay: Overlay) => void
}

export const OverlayContext = React.createContext<OverlayContextValues>({
  currentOverlay: null,
  hideOverlays: undefined,
  projects: [],
  setProjects: undefined,
  about: [],
  setAbout: undefined,
  toggleOverlay: undefined
})

interface IProps {
  children: React.ReactNode
}

export const OverlayContextProvider: React.FC<IProps> = ({ children }) => {
  const {
    navigationColour,
    setNavigationColour,
    cachedColour,
    setCachedColour
  } = useContext(NavigationContext)
  const [currentOverlay, setCurrentOverlay] = useState(null)
  const [projects, setProjects] = useState([])
  const [about, setAbout] = useState([])

  const hideOverlays = () => {
    setNavigationColour(cachedColour)
    setCurrentOverlay(null)
    document.body.style.overflow = 'auto'
  }

  const toggleOverlay = (overlay: Overlay) => {
    // only cached navigation text color if entering overlay, not when switching from one overlay to another
    if (currentOverlay === null) {
      setCachedColour(navigationColour)
      setNavigationColour('#000')
    }

    // hide overlay
    if (currentOverlay === overlay) {
      setNavigationColour(cachedColour)
      setCurrentOverlay(null)
      setTimeout(() => {
        document.body.style.overflow = 'auto'
      }, 200)
    } else {
      // show overlay
      setCurrentOverlay(overlay)
      setTimeout(() => {
        document.body.style.overflow = 'hidden'
      }, 200)
    }
  }

  const defaultValue = {
    currentOverlay,
    hideOverlays,
    projects,
    setProjects,
    about,
    setAbout,
    toggleOverlay
  }

  return (
    <OverlayContext.Provider value={defaultValue}>
      {children}
    </OverlayContext.Provider>
  )
}
