import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

export const VersionNumber = () => {
  return (
    <StaticQuery
      query={query}
      render={data =>
        <p style={{ opacity: 0.2 }}>
          v{data.site.siteMetadata.version}
        </p>}
    />
  )
}

const query = graphql`
  query VersionNumberQuery {
    site {
      siteMetadata {
        version
      }
    }
  }
`
