import React from 'react'
import styled from 'styled-components'

import { media } from '../../styles/utils'

const Container = styled.div`
  overflow-y: auto;
  padding-top: 80px;
  grid-row: 3 / 7;
  grid-column: 4 / 8;

  ${media.large`
    grid-column: 3 / 9;
  `}

  ${media.phone`
    grid-column: 2 / 10;
  `}
`

interface Props {
  data: any
}

export const About: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      {data.map((item, i) => (
        <div
          key={`markdown-item-${i}`}
          className="markdown-content"
          dangerouslySetInnerHTML={{
            __html:
              item.childContentfulTextContentTextNode.childMarkdownRemark.html
          }}
        />
      ))}
    </Container>
  )
}
