import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition } from 'react-spring/renderprops'

import { MasterGrid } from './layout/MasterGrid'
import { defaultSpring } from '../utils/Springs'

const Screen = styled(MasterGrid)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.layers.loaders};
  background: white;
`

class OverlayWrapper extends React.Component {
  render() {
    const { show, children } = this.props
    return (
      <Transition
        config={defaultSpring}
        items={show}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(show) =>
          show &&
          ((style) => (
            <Screen className="overlay-wrapper" style={style}>
              {children}
            </Screen>
          ))
        }
      </Transition>
    )
  }
}

OverlayWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node
}

export default OverlayWrapper
