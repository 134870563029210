import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media, fluidPx } from '../../styles/utils'

const Svg = styled.svg`
  cursor: pointer;

  width: 21px;
  height: 21px;

  ${media.large`
    ${fluidPx(`width`, 16, 21)}
    ${fluidPx(`height`, 16, 21)}
  `}
  ${media.small`
    width: 16px;
    height: 16px;
  `}
`

export const Close = ({ onClick, style }) => (
  <Svg onClick={onClick} style={style} width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="close-button" fill="#000000">
        <polygon id="Path" points="21 2.1 18.9 0 10.5 8.4 2.1 0 0 2.1 8.4 10.5 0 18.9 2.1 21 10.5 12.6 18.9 21 21 18.9 12.6 10.5" />
      </g>
    </g>
  </Svg>
)

Close.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object
}
