import React, { useContext } from 'react'

import OverlayWrapper from '../OverlayWrapper'
import { ProjectsTable } from './ProjectsTable'
import { Overlay, OverlayContext } from '../../contexts/OverlayContext'
import { ProjectRow } from '../../lib/types'

interface Props {
  data: ProjectRow[]
}

export const ProjectsOverlay: React.FC<Props> = ({ data }) => {
  const { currentOverlay } = useContext(OverlayContext)
  const show = currentOverlay === Overlay.Projects
  return (
    <OverlayWrapper show={show}>
      {data.length > 0 ? <ProjectsTable data={data} /> : null}
    </OverlayWrapper>
  )
}
