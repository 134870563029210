import React, { useState } from 'react'

export interface ScrollContextValues {
  shouldScroll: boolean
  setShouldScroll: (value: boolean) => void
}

export const ScrollContext = React.createContext<ScrollContextValues>({
  shouldScroll: false,
  setShouldScroll: undefined
})

interface IProps {
  children: React.ReactNode
}

export const ScrollContextProvider: React.FC<IProps> = ({ children }) => {
  const [shouldScroll, setShouldScroll] = useState(false)

  const defaultValue = {
    shouldScroll,
    setShouldScroll
  }

  return (
    <ScrollContext.Provider value={defaultValue}>
      {children}
    </ScrollContext.Provider>
  )
}
