import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import Debug from 'debug'

import theme from '../styles/theme'
import config from '../utils/siteConfig'
import tabIcon from '../images/tabIcon.png'

import { VersionNumber } from '../components/VersionNumber' // works with .jsx

import { NavigationBar } from './NavigationBar'
import { ProjectsOverlay } from './ProjectsOverlay'
import { AboutOverlay } from './AboutOverlay'
import { GlobalStyle } from '../styles/global'

import '../css/swiper.css'

const debug = Debug('site:layout')

class Layout extends React.Component {
  constructor(props) {
    super(props)
    // Set state
    this.state = {
      isLoading: true,
      isIE: undefined
    }
  }

  async componentDidMount() {
    debug('mounted')

    debug('----- starting client checks -----')
    // Detect IE
    const isIE = this.detectIE()
    debug('isIE', isIE)

    // https://stackoverflow.com/questions/5529718/how-to-detect-internet-speed-in-javascript
    // const clientConnection = await connectionSpeed()
    // debug(`connectionSpeed: ${clientConnection.speedMbps}`)
    debug('----- finished client checks -----')

    // Set state
    // this.props.setUserConnectionSpeed(clientConnection.speedMbps)

    this.setState({
      isLoading: false,
      isIE
    })
  }

  /**
   * TODO: move to lib
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE = () => {
    var ua = window.navigator.userAgent

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ')
    if (msie > 0) {
      // IE 10 or older => return version number
      // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      return true
    }

    var trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      // var rv = ua.indexOf('rv:');
      // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      return true
    }

    // var edge = ua.indexOf('Edge/');
    // if (edge > 0) {
    //   // Edge (IE 12+) => return version number
    //   return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    // }

    // other browser
    return false
  }

  render() {
    const { children } = this.props
    debug('render', this.state.isLoading)

    if (this.state.isLoading) {
      return null
    }

    return (
      <div className="root">
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href={tabIcon} />
          <meta name="description" content={config.siteDescription} />
          <meta property="og:title" content={config.siteTitle} />
          <meta property="og:url" content={config.siteUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={config.siteTitle} />
        </Helmet>

        {this.state.isIE ? (
          <>
            <h1>{`Internet Explorer: ${this.state.isIE}`}</h1>
            <VersionNumber />
          </>
        ) : (
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <div className="layout">
              <NavigationBar />
              {children}
              <StaticQuery
                query={query}
                render={(data) => {
                  console.log('version: ', data.site.siteMetadata.version)
                  const {
                    rows
                  } = data.allContentfulProjectsOverlay.edges[0].node
                  const markdown =
                    data.allContentfulAboutOverlay.edges[0].node.text
                  return (
                    <>
                      <ProjectsOverlay data={rows} />
                      <AboutOverlay data={markdown} />
                    </>
                  )
                }}
              />
            </div>
          </ThemeProvider>
        )}
      </div>
    )
  }
}

const query = graphql`
  query {
    site {
      siteMetadata {
        version
      }
    }
    allContentfulProjectsOverlay {
      edges {
        node {
          name
          rows {
            id
            name
            year
            location
            program
            client
            size {
              internal {
                content
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            href
          }
        }
      }
    }
    allContentfulAboutOverlay {
      edges {
        node {
          name
          text {
            childContentfulTextContentTextNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`

export default Layout
