import React, { useContext } from 'react'
import styled from 'styled-components'
import { Transition } from 'react-spring/renderprops'

import { Close } from '../svg/Close'
import { Overlay, OverlayContext } from '../../contexts/OverlayContext'

export const Link = styled.p`
  color: ${(props) => props.color};
  transition: ${(props) => props.theme.transitions.navColor};
  margin: 0;
  cursor: ${(p) => (p.clickable ? 'pointer' : 'initial')};

  ${(p) =>
    p.hoverEffect
      ? `/*effect-underline*/
      &:after {
        content: '';
        position: absolute;
        left: 0;
        display: inline-block;
        height: 1em;
        width: 100%;
        border-bottom: 3px solid;
        margin-top: 8px;
        opacity: 0;
        transition: opacity 0.35s, transform 0.35s;
        transform: scale(0,1);
      }

      &:hover:after {
        opacity: 1;
        transform: scale(1);
      }`
      : ``}
`

interface Props {
  onClick: () => any
  color: string
  title: string
  index: Overlay
  hoverEffect: boolean
  className: string
  clickable: boolean
}

export const NavigationLink: React.FC<Props> = ({
  onClick,
  color,
  title,
  index,
  hoverEffect,
  className,
  clickable
}) => {
  const { currentOverlay } = useContext(OverlayContext)
  const overlayVisible = currentOverlay === index
  return overlayVisible ? (
    <Transition
      items={overlayVisible}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {(overlayVisible) =>
        overlayVisible && ((style) => <Close style={style} onClick={onClick} />)
      }
    </Transition>
  ) : (
    <Link
      clickable={clickable}
      className={className}
      hoverEffect={hoverEffect}
      color={color}
      onClick={onClick}
    >
      {title}
    </Link>
  )
}
