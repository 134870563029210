import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { screenSizes } from '../../../styles/utils'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

export const Image = styled(GatsbyImage)`
  height: 100%;

  & > img {
    object-fit: cover !important;
    object-position: '50% 50%' !important;
    font-family: 'object-fit: cover !important; object-position: 50% 50% !important;';
  }
`

interface Props {
  image: any
  mobileImage?: any
  className?: string
}

export const BackgroundImage: React.FC<Props> = ({
  image,
  mobileImage,
  className
}) => {
  let display = image
  if (mobileImage && window.innerWidth < screenSizes.tablet) {
    display = mobileImage
  }

  return (
    <Container className={className}>
      <Image image={display} alt="background-image" />
    </Container>
  )
}
