import React from 'react'
import styled from 'styled-components'

const DataCellContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.7);
  padding-right: 2.25em;

  ${p => p.rowHovered ? `
    cursor: pointer;
    p {
      font-weight: bold;
    }
  ` : ``}
`

export const DataCell = ({ children, rowHovered, onHover, onHoverOut, uuid, ...props }) => {
  const handleHover = () => {
    if (onHover) {
      onHover(uuid)
    }
  }

  const handleHoverOut = () => {
    if (onHoverOut) {
      onHover()
    }
  }

  return (
    <DataCellContainer rowHovered={rowHovered} {...props} onPointerEnter={handleHover} onPointerOut={handleHoverOut}>
      {children}
    </DataCellContainer>
  )
}
