import React, { useContext } from 'react'

import OverlayWrapper from '../OverlayWrapper'
import { About } from './About'
import { Overlay, OverlayContext } from '../../contexts/OverlayContext'

interface Props {
  data: any
}

export const AboutOverlay: React.FC<Props> = ({ data }) => {
  const { currentOverlay } = useContext(OverlayContext)
  const show = currentOverlay === Overlay.About
  return (
    <OverlayWrapper show={show}>
      {data.length > 0 ? <About data={data} /> : null}
    </OverlayWrapper>
  )
}
