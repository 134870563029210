import styled from 'styled-components'
import { media } from '../../styles/utils'

export const MasterGrid = styled.div`
  display: grid;
  grid-template-rows: 36px 50px 80px 1fr 80px 50px 36px;
  grid-template-columns: 36px 50px 80px 0.248fr 0.252fr 0.252fr 0.248fr 80px 50px 36px;
  overflow: hidden;

  ${media.tablet`
    grid-template-rows: 20px 50px 80px 1fr 80px 50px 20px;
    grid-template-columns: 20px 50px 80px 0.248fr 0.252fr 0.252fr 0.248fr 80px 50px 20px;
  `}
`
