export const theme = {
  colors: {
    base: '#121212', // Black
    secondary: '#e9e9e9', // Medium Gray
    tertiary: '#f3f3f3', // Light Gray
    highlight: '#5b8bf7', // Light Blue,
    black: '#0c0c0c',
    blackLight: '#333',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',

    slidePadding: 36,
    slidePaddingMobile: 20
  },
  scales: {
    perfectFifth: [0.667, 1, 1.5, 2.25, 3.375, 5.063],
    majorThird: [0.8, 1, 1.25, 1.563, 1.953, 2.441, 3.052, 3.815, 4.768, 5.96],
    goldenSection: [0.618, 1, 1.618, 2.618, 4.236, 6.854, 11.089]
  },
  fonts: {
    normal: 'HelveticaLtStd-Light, Helvetica, Arial, sans-serif',
    medium: 'HelveticaLtStd-Roman, Helvetica, Arial, sans-serif',
    bold: 'HelveticaLtStd-Bold, Helvetica, Arial, sans-serif'
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  transitions: {
    navColor: 'all 0.35s ease-in-out',
  },
  slides: {
    transitionDuration: '0.9s',
    duration: 900,
    easing: 'cubic-bezier(0.455, 0.030, 0.515, 0.955)' /* easeInOutQuad - https://matthewlein.com/tools/ceaser */
  },
  layers: {
    slideIndicator: 90,
    loaders: 100,
    navigation: 102,
    navigationArrow: 99
  }
}

export default theme
