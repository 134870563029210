import React, { useState } from 'react'

type NavigationColour = '#FFF' | '#000'
export interface NavigationContextValues {
  navigationColour: NavigationColour
  setNavigationColour: (colour: NavigationColour) => void
  cachedColour: NavigationColour | null
  setCachedColour: (colour: NavigationColour | null) => void
}

export const NavigationContext = React.createContext<NavigationContextValues>({
  navigationColour: '#FFF',
  setNavigationColour: undefined,
  cachedColour: null,
  setCachedColour: undefined
})

interface IProps {
  children: React.ReactNode
}

export const NavigationContextProvider: React.FC<IProps> = ({ children }) => {
  const [navigationColour, setNavigationColour] = useState<NavigationColour>(
    '#FFF'
  )
  const [cachedColour, setCachedColour] = useState(null)

  const defaultValue = {
    navigationColour,
    setNavigationColour,
    cachedColour,
    setCachedColour
  }

  return (
    <NavigationContext.Provider value={defaultValue}>
      {children}
    </NavigationContext.Provider>
  )
}
