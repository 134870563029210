import React, { useContext } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { media } from '../../styles/utils'

import { NavigationLink } from './NavigationLink'

import { NavigationContext } from '../../contexts/NavigationContext'
import { Instagram } from './Instagram.svg'
import { Overlay, OverlayContext } from '../../contexts/OverlayContext'

const Container = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.layers.navigation};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: ${(props) => props.theme.sizes.slidePadding}px;
  ${media.tablet`
    padding: ${(props) => props.theme.sizes.slidePaddingMobile}px;
    grid-template-columns: 1fr 2fr 1fr;
  `}
`

const Li = styled.li`
  display: inherit;
  align-self: start;
  justify-self: ${(props) => props.justify};
  position: relative;
`

const BottomRight = styled.a`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${(props) => props.theme.layers.navigation};
  padding: ${(props) => props.theme.sizes.slidePadding}px;
  ${media.tablet`
    padding: ${(props) => props.theme.sizes.slidePaddingMobile}px;
    grid-template-columns: 1fr 2fr 1fr;
  `}

  path {
    transition: all 0.2s ease;
  }
`

export const NavigationBar: React.FC = () => {
  const { currentOverlay, hideOverlays, toggleOverlay } = useContext(
    OverlayContext
  )
  const { navigationColour, setNavigationColour } = useContext(
    NavigationContext
  )

  const handleBrand = () => {
    if (currentOverlay) {
      hideOverlays()
    }

    if (window.location.pathname !== '/') {
      hideOverlays()
      setNavigationColour('#FFF')
      navigate('/')
    }
  }

  const handleUpdates = () => {
    window.open('https://www.instagram.com/studiohollenstein/', '_blank')
  }

  let onHomePage = false
  if (typeof window !== 'undefined') {
    onHomePage = window.location.pathname === '/'
  }

  let brandIsClickable = !onHomePage

  return (
    <>
      <Container>
        <Li justify="start">
          <NavigationLink
            clickable
            onClick={() => toggleOverlay(Overlay.About)}
            color={navigationColour}
            title={Overlay.About}
            index={Overlay.About}
            hoverEffect
            className={'bold'}
          />
        </Li>
        <Li justify="center">
          <NavigationLink
            clickable={brandIsClickable}
            onClick={handleBrand}
            color={navigationColour}
            title="Studio Hollenstein"
            index="home"
            hoverEffect={brandIsClickable}
            className={'brand'}
          />
        </Li>
        <Li justify="end">
          <NavigationLink
            clickable
            onClick={() => toggleOverlay(Overlay.Projects)}
            color={navigationColour}
            title={Overlay.Projects}
            index={Overlay.Projects}
            hoverEffect
            className={'bold'}
          />
        </Li>
      </Container>
      <BottomRight onClick={handleUpdates}>
        <Instagram colour={navigationColour} />
      </BottomRight>
    </>
  )
}
