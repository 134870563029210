import React from 'react'
import objectFitImages from 'object-fit-images'
import Debug from 'debug'

import { NavigationContextProvider } from './src/contexts/NavigationContext'
import { OverlayContextProvider } from './src/contexts/OverlayContext'
import { ScrollContextProvider } from './src/contexts/ScrollContext'

const debug = Debug('site:gatsby:browser')

// https://www.gatsbyjs.org/docs/browser-apis/#onClientEntry
// https://stackoverflow.com/questions/14341156/calculating-page-load-time-in-javascript
export const onClientEntry = () => {
  debug('onClientEntry')

  window.addEventListener(
    'load',
    () => {
      debug('window:load')
    },
    false
  )
}

export const wrapRootElement = ({ element }) => (
  <NavigationContextProvider>
    <OverlayContextProvider>
      <ScrollContextProvider>{element}</ScrollContextProvider>
    </OverlayContextProvider>
  </NavigationContextProvider>
)

export const onInitialClientRender = () => {
  debug('onInitialClientRender')
  debug('active polyfill for object-fit-images')
  objectFitImages()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  debug('onRouteUpdate', location, prevLocation)
  if (location.pathname === '/' && prevLocation) {
    debug('navigating to homepage...')

    const ripple = document.createElement('div')
    ripple.className = 'lds-ripple'
    ripple.appendChild(document.createElement('div'))
    ripple.appendChild(document.createElement('div'))

    const text = document.createElement('p')
    text.innerHTML = 'Loading studiohollenstein.com, quickly.'

    const container = document.createElement('div')
    container.id = 'preloader'

    container.appendChild(text)
    container.appendChild(ripple)

    const body = document.getElementsByTagName('body')[0]
    body.appendChild(container)
  }
}
